import React from "react";
import { Helmet } from "react-helmet";
import SEO from "./SEO";
import "../assets/main.scss";
import logo from "../images/main-logo.svg";
import { navigate, graphql } from "gatsby";
import { BiArrowBack } from "@react-icons/all-files/bi/BiArrowBack";
import FadeIn from "react-fade-in";

const BookingLayout = (props) => (
  <>
    {/* <SEO title={"Pestgogo Booking"} /> */}
    <div className="booking">
      <div
        className="back"
        onClick={() => {
          if (props.checkoutback) navigate("/");
          else
            props.index && props.index > 0
              ? props.setIndex(props.index - 1)
              : navigate(props.back);
        }}
      >
        <div>
          <BiArrowBack />
        </div>
      </div>
      <a href="#">
        <img
          onClick={() => navigate("/")}
          className="logg"
          src={logo}
          alt="Best Pest Service in India"
        />
      </a>
      <FadeIn>
        <div className="booking-main">
          <div className="titleBB">
            <div>
              {props.imm && <img src={props.imm} height="50px" alt="" />}
            </div>
            <div>
              {" "}
              <h2>{props.title}</h2>{" "}
            </div>
          </div>

          {props.children}
        </div>
      </FadeIn>
    </div>
  </>
);

export default BookingLayout;
